import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu'

import prayers from './data/prayers.json';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPrayersSublist: true, // Initially, the sublist is hidden
    };
  }

  togglePrayersSublist = () => {
    this.setState((prevState) => ({
      showPrayersSublist: !prevState.showPrayersSublist,
    }));
  };

  render() {
    const { showPrayersSublist } = this.state;

    return (
      <HeaderBar>
        <Menu styles={styles}>
          <a id="home" className="menu-item" href="/">
            <span>&#127968;</span>Home
          </a>
          <div
            id="prayers"
            className={`menu-item ${showPrayersSublist ? 'active' : ''}`}
            onClick={this.togglePrayersSublist}
          >
            <span>&#10013;</span> Böner
            {showPrayersSublist && (
              <SublistContainer>
                {prayers.map((prayer) => (
                  <SublistLink
                    key={prayer.id}
                    to={`/prayers/${prayer.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    - {prayer.title}
                  </SublistLink>
                ))}
              </SublistContainer>
            )}
          </div>

        </Menu>
        <HomeLink to="/">
          <Title>
            <span>&#10013;</span> Svenska Katoliker
          </Title>
        </HomeLink>
      </HeaderBar>
    );
  }
}

/** Header styled components */
const HeaderBar = styled.div({
  // display: 'flex',
  // flexDirection: 'column',
  // alignItems: 'left',
  // justifyContent: 'left',
  padding: '0px 20px',
  backgroundColor: "#800080",
  zIndex: 999,
});

const HomeLink = styled(Link)({
  textDecoration: "none",
  color: "white",
});

const Title = styled.div({
  padding: 30,
  fontSize: 30,
  fontWeight: "bold",
  color: "white",
});

const SublistContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const SublistLink = styled(Link)({
  fontSize: 16,
  paddingTop: 5,
  textDecoration: "none",
  color: "white",
  "&:hover": {
    textDecoration: "none", // Remove underline on hover
    color: "white",         // Maintain white color on hover
  },
});

var styles = {
  bmBurgerButton: {
    position: 'absolute',
    width: '30px',
    height: '25px',
    left: '5px',
    top: '36px',
  },
  bmBurgerBars: {
    background: 'white'
  },
  bmBurgerBarsHover: {
    background: 'white'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: 'white'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    left: '0',
    top: '0'
  },
  bmMenu: {
    background: '#800080',
    padding: '1.5em 0.5em 0',
    fontSize: '1.5em',
  },
  // bmMorphShape: {
  //   fill: 'green'
  // },
  bmItemList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    // padding: '0.8em',
  },
  bmItem: {
    display: 'inline-block',
    textDecoration: "none",
    color: "white",
    padding: '0.8em',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}

export default Header;
