import React from "react";
import styled from '@emotion/styled';

class Footer extends React.Component {
    render() {
        return (
            <Border>
                <div>© {new Date().getFullYear()} Svenska Katoliker</div>
                <div>
                    <a href="mailto:svenskakatoliker@gmail.com" style={{ color: 'white', marginTop: '10px' }}>svenskakatoliker@gmail.com</a>
                </div>
            </Border>
        )
    };
};

const Border = styled.div({
    color: 'white',
    backgroundColor: '#800080',
    padding: '30px',
    fontSize: 14,
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column', // Stack the divs vertically
    alignItems: 'center', // Center-align the content horizontally
});

export default Footer;
