

import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
/** importing our pages */
import App from './App';
import Prayers from './Components/Prayers/Prayers';
import Prayer from './Components/Prayers/Prayer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Router>
      <Routes>
        <Route element={<App />} path="/" />
        <Route element={<Prayers />} path="/prayers" />
        <Route element={<Prayer />} path="/prayers/:prayerId" />
      </Routes>
    </Router>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
