
import React from "react";
import styled from '@emotion/styled';

const PrayerCard = ({ prayer }) => {
  const {
    id,
    title,
    titleEnglish,
    text,
  } = prayer;

  return (
      <PrayerContainer key={id}>
        <Card>
          <Header>
            <h2>{title}</h2>
            {titleEnglish && <h3>{"(" + titleEnglish + ")"}</h3>}
          </Header>
          <PrayerText>
            {text.split('\n').map((line, i) => (
              <div key={i}>
                {line.split('*').map((segment, j) => (
                  <React.Fragment key={j}>
                    {j % 2 === 0 ? (
                      segment // Non-italicized segment
                    ) : (
                      <i>{segment}</i> // Italicized segment
                    )}
                  </React.Fragment>
                ))}
                {i < text.split('\n').length - 1 && <br />}
              </div>
            ))}
          </PrayerText>
        </Card>
      </PrayerContainer>
  );
};


const PrayerContainer = styled.div({
  padding: '10px',
  maxWidth: '500px',
  minWidth: '45vh',
  margin: '10px',
});

const Card = styled.div({
  borderRadius: '30px',
  color: '#800080',
  backgroundColor: 'white',
  boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.15)',
});

const Header = styled.div({
  textAlign: 'center',
  padding: '20px',
  "& h2": {
    margin: '0',
  },
  "& h3": {
    margin: '0',
    marginTop: '5px', // Adjust the top margin to control spacing
  },
});

const PrayerText = styled.div({
  padding: '20px',
  lineHeight: '1.5',
  color: 'black',
});

export default PrayerCard;