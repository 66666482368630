import styled from '@emotion/styled';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ fullWidth, children, grid }) => {
  return (
    <Wrapper>
      <Header />
      <PageContainer fullWidth={fullWidth} grid={grid}>
        {children}
      </PageContainer>
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

/** Layout styled components */
const PageContainer = styled.div((props) => ({
  flex: '1',
  // display: 'grid',
  // gridTemplateRows: 'auto 1fr auto', // Header, Content, Footer
  // gridGap: '16px', // Adjust as needed
  // maxWidth: props.fullWidth ? null : `${1100}px`,
  width: '100%',
  // padding: props.fullWidth ? 0 : '16px',
  // boxSizing: 'border-box',
}));

export default Layout;
