import React from "react";
import { useParams, Link } from "react-router-dom";
import PrayerCard from "./PrayerCard";
import styled from "@emotion/styled";

import prayers from '../data/prayers.json'
import Layout from "../Layout";

const Prayer = () => {
  const { prayerId } = useParams();
  // Find the index of the current prayer by ID in the prayers array
  const currentIndex = prayers.findIndex((prayer) => prayer.id === parseInt(prayerId));

  if (currentIndex === -1) {
    return <div>Prayer not found</div>;
  }

  const previousPrayer = prayers[currentIndex - 1];
  const nextPrayer = prayers[currentIndex + 1];

  return (
    <Layout>
      <Button>
        <a id="home" className="menu-item" href="/">
          Tillbaka
        </a>
      </Button>

      <PrayerCard prayer={prayers[currentIndex]} />
      <ButtonContiner>
        <Button>
          {previousPrayer && (
            <ButtonLink
              key={previousPrayer.id}
              to={`/prayers/${previousPrayer.id}`}
              style={{ textDecoration: "none" }} // Add this style to remove underlines
            >
              {"< Föregående: "} {previousPrayer.title}
            </ButtonLink>
          )}
        </Button>
        <Button>
          {nextPrayer && (
            <ButtonLink
              key={nextPrayer.id}
              to={`/prayers/${nextPrayer.id}`}
              style={{ textDecoration: "none" }} // Add this style to remove underlines
            >
              {"> Nästa:"} {nextPrayer.title}
            </ButtonLink>
          )}
        </Button>
      </ButtonContiner>
    </Layout>
  );
};

const ButtonContiner = styled.div({
  display: 'flex',
  flexDirection: 'column',
});


const Button = styled.div({
  paddingLeft: 50,
  textDecoration: "none",
  color: "white",
  "&:hover": {
    textDecoration: "none", // Remove underline on hover
    color: "white",         // Maintain white color on hover
  },
});

const ButtonLink = styled(Link)({
  fontSize: 16,
  textDecoration: "none",
  fontWeight: "bold",
  color: "#800080",
  "&:hover": {
    textDecoration: "none", // Remove underline on hover
    color: "#800080",         // Maintain white color on hover
  },
});

export default Prayer;
