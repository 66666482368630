import React from "react";
import styled from '@emotion/styled';
import { Link } from "react-router-dom";
import PrayerCard from "./PrayerCard";
import Layout from '../Layout';
import prayers from '../data/prayers.json';

class Prayers extends React.Component {
  render() {
    return (
      <Layout>
        <CardWrapper>
        <Title>Böner</Title>
          <SublistContainer>
            {prayers.map((prayer) => (
              <SublistLink
                key={prayer.id}
                to={`/prayers/${prayer.id}`}
                style={{ textDecoration: "none" }}
              >
                - {prayer.title}
              </SublistLink>
            ))}
          </SublistContainer>
          <Title>Alla böner</Title>
          {prayers.map((prayer) => (
            <Link
              key={prayer.id}
              to={`/prayers/${prayer.id}`}
              style={{ textDecoration: "none" }} // Add this style to remove underlines
            >
              <PrayerCard prayer={prayer} />
            </Link>
          ))}
        </CardWrapper>
      </Layout>
    );
  }
}

const Title = styled.div({
  padding: 30,
  fontSize: 30,
  fontWeight: 'bold'
});


const CardWrapper = styled.div({
  minHeight: '100vh',
});

const SublistContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const SublistLink = styled(Link)({
  fontSize: 16,
  paddingLeft: 20,
  paddingTop: 5,
  textDecoration: "none",
  color: "#800080",
  "&:hover": {
    textDecoration: "none", // Remove underline on hover
    color: "#800080",         // Maintain white color on hover
  },
});

export default Prayers;
