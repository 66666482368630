import './App.css';
import Prayers from './Components/Prayers/Prayers';

function App() {
  return (
      <Prayers></Prayers>
  );
}

export default App;
